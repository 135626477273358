<template>
  <v-list-item
    dense
    @click="handlePassportObjectItem"
    :title="item.title"
    :style="item.opacity ? 'opacity:' + item.opacity : ''"
    :class="findActiveNav ? 'active-item-nav' : ''"
  >
    <v-list-item-icon
      class="ml-1"
      v-if="item.icon"
    >
      <v-badge
        :value="mini && !!Number(sizeCheckList)"
        v-if="item.withNotificationIcon"
        :content="item.withNotificationIcon && !!sizeCheckList ? sizeCheckList : ''"
      >
        <v-icon small>{{ item.icon }}</v-icon>
      </v-badge>
      <v-icon
        v-if="!item.withNotificationIcon"
        small
      >{{ item.icon }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-title v-if="!item.withNotificationIcon">
      {{ item.title }}
    </v-list-item-title>
    <v-list-item
      class="item-with-badge"
      v-if="item.withNotificationIcon"
    >
      <v-badge
        :value="!!Number(sizeCheckList)"
        :content="sizeCheckList"
      >{{ item.title }}
      </v-badge>
    </v-list-item>
  </v-list-item>
</template>

<script>

export default {
  name: 'BaseListItem',
  props: {
    item: Object,
    mini: Boolean,
    isMap: { // route.name использовать не могу т.к тогда не будет работать основой бар навигации
      type: Boolean,
      default: false
    }
  },
  methods: {
    handlePassportObjectItem () {
      if (this.isMap) {
        this.$emit('click', null)
      } else {
        this.$router.push({ path: this.item.link }).catch(error => {
          if (
            error.name !== 'NavigationDuplicated' &&
            !error.message.includes('Avoided redundant navigation to current location')
          ) {
            console.log(error)
          }
        })
      }
    }
  },
  computed: {
    sizeCheckList () {
      return this.$store.getters['serviceData/countOfVerifications']
    },
    findActiveNav () {
      if (!this.isMap) {
        const checkLink = this.item.link.match(/\/account\/\w+\b/)[0] //поиск строки /account/something
        const checkRoute = this.$route.path.match(/\/account\/[a-z]+/)[0] //поиск такой же строки, но внутри большой ссылки /account/something
        const checkWrongLink = this.item.link.match(/\/[a-z]+\/[a-z]+\//)// проверка на то, что ссылка похожа на /account/something/somethingElse..., чтобы не выделять ее

        return checkLink === checkRoute && !checkWrongLink
      }
      // если пришедшая ссылка соответствует роуту, и это не ссылка от компонента(например: /account/passport/dashboard)
    }
  }
}
</script>

<style scoped>
.item-with-badge {
  font-size: 11px;
  padding: 0 !important
}
.active-item-nav {
  background: #ecf5ff;
}
/deep/ .v-badge {
  line-height: unset;
}
</style>
