<template>
  <v-navigation-drawer
    color="e1e1e1"
    stateless
    v-model="drawer"
    :mini-variant="mini"
  >
    <div class="d-flex align-center">
      <img
        width="150px;"
        class="ml-2 mt-7 mb-7 logo"
        src="~@/assets/logo.svg"
        @click="mini = !mini"
        alt=""
      >
    </div>
    <v-divider></v-divider>
    <v-list
      nav
      dense
      class="pa-0 pt-1"
    >
      <v-menu
        :close-on-content-click="false"
        right
        offset-x
        down
        transition="scale-transition"
        z-index="100"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item
            v-on="mini && on"
            @click="!mini && handleProfile()"
            :class="activeLink ? 'active-item-nav' : ''"
            class="pl-1"
          >
            <v-badge
              avatar
              bordered
              color="white"

              offset-x="18"
              offset-y="17"
            >
              <template v-slot:badge>
                <div class="pt-1 black--text">{{ shortName(name).toLowerCase() }}</div>
              </template>
              <v-avatar
                size="48"
                :class="avatarColor"
              >
                <div class="white--text">{{ shortName(currentRoleLabel).toUpperCase() }}</div>
              </v-avatar>
            </v-badge>
            <v-list-item-title
              class="ml-6"
            >
              Мой профиль
            </v-list-item-title>
            <v-list-item-action class="ma-0">
              <v-btn
                icon
                link
                :to="{name: 'login'}"
                x-small
              >
                <v-icon
                  @click="logout"
                >mdi-logout
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-card
          max-width="270"
          class="pa-0 pb-1 pt-4"
        >
          <div
            class="white--text caption ml-1 mr-1 error pl-2 pr-2 pt-1 pb-1 mb-1 rounded-pill lh-normal"
            v-if="daysOfRemaining <= 30 && subscriptions.length"
          >
            {{ remainingSubsc }}
          </div>
          <p class="primary--text ma-0 mb-1 pr-4 pl-4">{{ currentRoleLabel }}</p>
          <v-card-title
            style="word-break: break-word"
            class="pa-0 ma-0 mb-4 pr-4 pl-4"
          >{{ name }}
          </v-card-title>
          <div class="d-flex flex-row ">
            <v-list>
              <v-list-item dense>
                <RoleSelect
                  v-if="profile.organization"
                  v-model="currentRole"
                  :role-list="profile.role"
                  :clearable="false"
                  :label="$t('organization.role')"
                />
              </v-list-item>
              <v-list-item
                @click="handleProfile"
                dense
              >
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="pr-2">
                  Мой профиль
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="logout"
                :to="{name: 'login'}"
                dense
              >
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="pr-2">
                  Выйти
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-menu>
    </v-list>
    <v-divider></v-divider>
    <v-list>
      <BaseListItem
        v-for="(item,i) in getMenuItems"
        :key="i"
        :item="item"
        :mini="mini"
        v-model="mini"
      />
    </v-list>
    <v-spacer></v-spacer>
    <v-list
      nav
      dense
      class="mt-10"
    >
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { collectMenuItems } from '@/components/utils/common'
import menuItems from '@/config/menuConfigs/baseMenuConfig'
import BaseListItem from '@/components/base/BaseNav/BaseListItem'
import BaseBtn from '@/components/base/UI/BaseBtn'
import { roles } from '@/config/common'
import RoleSelect from '@/components/views/account/user/detail/role/RoleSelect'
import user from '@/components/mixins/user'
import '@/css/roleAvatarColor.css'
import BaseAlert from '@/components/base/UI/BaseAlert'
import endSubscription from '@/components/views/account/profile/detail/endSubscription'

export default {
  name: 'BaseNav',
  components: { BaseAlert, BaseListItem, BaseBtn, RoleSelect },
  mixins: [user, endSubscription],
  data () {
    return {
      drawer: true,
      mini: true
    }
  },
  computed: {
    currentRoleLabel () {
      const roleEng = this.$store.getters['auth/getCurrentRole']
      return roles.find(item => item.name === roleEng)?.label
    },
    name () {
      return this.$store.getters['auth/getName']
    },
    getMenuItems () {
      return collectMenuItems(menuItems)
    },
    activeLink () {
      const checkLink = '/account/profile'
      let checkRoute
      if (this.$route.path.match(/\/account\/[a-z -]+/)) {
        checkRoute = this.$route.path.match(/\/account\/[a-z -]+/).hasOwnProperty(0) ?
          this.$route.path.match(/\/account\/[a-z -]+/)['0'] :
          this.$route.path.match(/\/account\/[a-z -]+/)[0]
      }
      return checkLink === checkRoute
    },
    avatarColor () {
      let classStyle = this.$store.getters['auth/getCurrentRole'] + '-color'
      if (this.daysOfRemaining <= 30 && this.subscriptions.length) {
        classStyle += ' subsEndsSoon'
      }
      return classStyle
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
    },
    handleProfile () {
      this.$router.push({ path: '/account/profile' }).catch(error => {
        if (
          error.name !== 'NavigationDuplicated' &&
          !error.message.includes('Avoided redundant navigation to current location')
        ) {
          console.log(error)
        }
      })
    },
    shortName (string) {
      if (!string) {
        return ''
      }
      const regexForOne = /^[\wА-Яа-я]{1,2}/g
      const splitString = string.split(' ')
      const find = splitString.length > 1 && splitString[1].length ? [splitString[0][0], splitString[1][0]] : string?.match(regexForOne)
      return find?.join('')
    }
  }
}
</script>

<style scoped>
  .logo {
    cursor: pointer;
  }
  .active-item-nav {
    background: #ecf5ff;
  }
  .subsEndsSoon {
    border: 4px solid red
  }
</style>
