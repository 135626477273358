import formatters from '@/components/mixins/formatters'
import moment from 'moment/moment'

export default {
  mixins: [formatters],
  methods: {
    checkExpiredSubsc (to_date) { //уже просроченная подписка
      return !!moment.unix(to_date).fromNow().split(' ').find(item => item === 'ago')
    }
  },
  computed: {
    subscriptions () {
      return this.$store.getters['auth/getProfile'].organization.subscriptions || []
    },
    daysOfRemaining () {
      let end = moment() || null
      if (this.subscriptions.length > 1) { //если подписок несколько
        this.subscriptions.map((item) => {
          if (item.to_date < end && !this.checkExpiredSubsc(item.to_date)) { //ищем ту, у которой меньше всего времени, кроме просроченной
            end = item.to_date
          }
        })
      } else {
        end = this.subscriptions[0]?.to_date
      }
      const today = moment()
      const endSubsc = moment.unix(end) // создание "момента" из MS

      return endSubsc.diff(today, 'days') // разница между текущим днем и концом подписки
    },
    remainingSubsc() {
      return this.daysOfRemaining > 0 ? ('Ваша подписка заканчивается через ' + this.daysOfRemaining + (this.daysOfRemaining >= 5 ? ' дней' : ' дня')) : 'Подписка закончилась'
    }
  },
  mounted () {
  }
}