<template>
  <div class="section-base">
    <div
      class="nav"
      :key="currentRole"
    >
      <BaseNav/>
    </div>
    <div class="content ml-0">
      <router-view :key="keyContent"/>
    </div>
    <BaseSnackList/>
  </div>
</template>

<script>
import '@/css/accountLayout.css'
import '@/css/rewriteStyles.css'
import BaseNav from '@/components/base/BaseNav/BaseNav'
import meta from '@/components/mixins/meta'
import BaseSnackList from '@/components/base/BaseSnackList/BaseSnackList'
import user from '@/components/mixins/user'

export default {
  mixins: [meta, user],
  name: 'AccountLayout',
  components: { BaseNav, BaseSnackList },
  data () {
    return {
      keyContent: 1
    }
  },
  watch: {
    currentRole: {
      async handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          return this.$nextTick(() => {
            this.keyContent += 1
          })
        }
      }
    }
  },
  async mounted () {
    this.$store.dispatch('region/fetch')
    this.$store.dispatch('handbook/fetch')
    this.$store.dispatch('serviceData/verificationCheck')
    setInterval(() => this.$store.dispatch('serviceData/verificationCheck'), 10 * 60 * 1000)
    return this.$store.dispatch('serviceData/fetch')
  }
}
</script>

<style scoped>
</style>
